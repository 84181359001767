<template>
  <div class="recent-books">
    <section class="banner">
      <div class="container">
        <h2 v-text="$t('Recently catalogued')"></h2>
      </div>
    </section>
    <section class="recent-books__list">
      <div class="container">
        <div v-for="(group, index) in groupedBooks" :key="index" class="row">
          <div
            v-for="(book, i) in group"
            :key="book.id"
            class="col-12 col-lg-6"
          >
            <book-card :key="book.id" :book="book" :index="i" />
          </div>
        </div>

        <div v-if="isLoading" class="m-auto t-center pt-4">
          <span class="spinner mx-auto mb-2"></span>
          <h2>{{ $t('Loading') }}...</h2>
        </div>

        <div
          v-if="pagination && pagination.hasNextPage() && !isLoading"
          class="t-center"
        >
          <button
            class="btn btn--secondary btn--view-all"
            @click="fetchBooks(pagination.getNextPage())"
            v-text="$t('View All')"
          ></button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import _chunk from 'lodash.chunk';
import _uniqBy from 'lodash.uniqby';
import BookCard from '@/components/bookCard/BookCard';
import books from '../../../api/books';

export default {
  components: {
    BookCard,
  },

  data() {
    return {
      books: [],
      pagination: null,
      perPage: 10,
      isLoading: false,
    };
  },

  computed: {
    groupedBooks() {
      return _chunk(this.books, 2);
    },
  },

  watch: {
    '$i18n.locale': function () {
      this.fetchBooks();
    },
  },

  created() {
    this.fetchBooks();
  },

  methods: {
    async fetchBooks(page = 1) {
      this.isLoading = true;
      const { results, pagination } = await books.recent({
        page,
        perPage: this.perPage,
        language: this.$i18n.locale,
      });

      this.pagination = pagination;
      this.books = _uniqBy(this.books.concat(results), ({ id }) => id);
      this.isLoading = false;
    },
  },
};
</script>

<i18n src="./recentBooks.json"></i18n>

<style lang="scss">
@import './recentBooks.scss';
</style>
